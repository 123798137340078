<template xmlns="http://www.w3.org/1999/html">
  <div class="flex-column">
    <!--banner-->
    <div class="banner-box">
      <div class="w1320 flex-column flex-center h100 banner-custom">
        <span class="banner-title animated fadeInDown">智慧水务</span>
        <span class="banner-shuoming animated fadeInDown delay-1s"
          >数据基座+三维数字孪生推动水务数字化转型</span
        >
      </div>
    </div>
    <!--    应用案例-->
    <div class="yingyong-box">
      <div class="w1320 flex-column flex-center h100">
        <span class="yingyong-title">应用案例</span>
        <el-carousel :interval="4000" type="card" height="394px">
          <el-carousel-item v-for="(item, index) in bannerList" :key="index">
            <img :src="item.src" />
            <div class="swiper-box-box flex-column flex-center">
              <span>{{ item.title }}</span>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>

    <!--    产品理念-->
    <div class="w1320 flex-column custom-linian">
      <span>行业痛点</span>
      <div class="flex-row flex-between custom-linian-box">
        <div class="flex-column flex-align custom-item">
          <div class="waiquan flex-column flex-align flex-center">
            <div class="lansequan flex-column flex-align flex-center">
              <img
                src="https://sbyd-website.oss-cn-hangzhou.aliyuncs.com/assets/heqi/pic_chuangxin.png"
              />
            </div>
          </div>
          <span>信息分散，数据孤岛</span>
          <span
            >营销客服、供水调度等业务系统烟囱林立，产生数据割裂现象，数据互通受阻，数据无法支撑业务可持续发展。</span
          >
        </div>

        <div class="flex-column flex-align custom-item">
          <div class="waiquan flex-column flex-align flex-center">
            <div class="lansequan flex-column flex-align flex-center">
              <img
                src="https://sbyd-website.oss-cn-hangzhou.aliyuncs.com/assets/heqi/pic_wending.png"
              />
            </div>
          </div>
          <span>数据治理能力弱</span>
          <span
            >生产运营数据普遍存在丢、少、错、重等现象，缺少有效手段规范化管理，致使数据治理能力弱，影响生产运营效率和质量。</span
          >
        </div>

        <div class="flex-column flex-align custom-item">
          <div class="waiquan flex-column flex-align flex-center">
            <div class="lansequan flex-column flex-align flex-center">
              <img
                src="https://sbyd-website.oss-cn-hangzhou.aliyuncs.com/assets/heqi/pic_anquan.png"
              />
            </div>
          </div>
          <span>数据利用效率低</span>
          <span
            >相同的业务需求，因缺少统一的共享标准而需要重复开发数据接口，导致开发和维护成本高，输出的数据。</span
          >
        </div>
      </div>
    </div>

    <!--    解决方案-->
    <div class="jiejuefangan">
      <div class="w1320 h100 flex-column flex-center flex-align custom-jiejue">
        <span>解决方案</span>
        <div class="flex-row">
          <img
            src="https://sbyd-website.oss-cn-hangzhou.aliyuncs.com/assets/water/Solut8_2.jpg"
          />
          <div class="shuoming flex-column flex-align flex-center">
            <span>
              基于数据治理引擎高效的数据治理能力、灵活的业务开发能力、标准的流程设计能力，并结合在项目上多年沉淀的行业经验，以及专门为解决智慧水务而设计的基本框架，能够完美解决数据孤岛、数据质量差、数据利用率低、水务业务创新难度大等场景痛点。
            </span>
          </div>
        </div>
      </div>
    </div>

    <!--    业务介绍-->
    <div class="w1320 flex-column flex-align custom-yewu">
      <span>核心优势</span>
      <div class="flex-row flex-between">
        <div
          class="flex-column flex-align custom-yewu-item"
          v-for="(item, index) in yewuList"
          :key="index"
        >
          <div class="img-box">
            <img :src="item.src" />
          </div>

          <span>{{ item.title }}</span>
          <span class="youshi-shuoming" v-html="item.shuoming"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "waterIndex",
  data() {
    return {
      bannerList: [
        {
          src: "https://sbyd-website.oss-cn-hangzhou.aliyuncs.com/assets/water/1-21112G20144P7.jpg",
          title: "供水指挥中心",
        },
        {
          src: "https://sbyd-website.oss-cn-hangzhou.aliyuncs.com/assets/water/1-21112G20252Y9.jpg",
          title: "智慧水厂大数据中心",
        },
        {
          src: "https://sbyd-website.oss-cn-hangzhou.aliyuncs.com/assets/water/1-21112G15923641.jpg",
          title: "智慧水厂数字孪生平台",
        },
      ],
      yewuList: [
        {
          src: "https://sbyd-website.oss-cn-hangzhou.aliyuncs.com/assets/water/Solut18_1.jpg",
          title: "高效构建海量数据应用",
          shuoming:
            "集成高效的数据引擎，能够处理物联网场景下的海量数据接入计算和展示渲染，配合在线或离线GIS能力和丰富的图上组件，能够轻松构架智慧水务官网检测、水源管控、泵站预警等应用。<br>",
        },
        {
          src: "https://sbyd-website.oss-cn-hangzhou.aliyuncs.com/assets/water/Solut18_2.jpg",
          title: "敏捷开发多类工单应用",
          shuoming:
            "内置敏捷的流程引擎，将工作流各个阶段需要的功能实现模块化和流程捷码内置敏捷的流程引擎，将工作流各个阶段需要的功能实现模块化和流程化，不仅能快速构建工单派发、处置反馈等流程应用，还能够自定义流程节点和流向模块，实现快速响应各地营业厅灵活多变的行业需求。<br>",
        },
        {
          src: "https://sbyd-website.oss-cn-hangzhou.aliyuncs.com/assets/water/Solut18_3.jpg",
          title: "快速开发炫酷大屏应用",
          shuoming:
            "内置多套行业模板，均经历多个项目实地验证，行业业务锲合度非常高。针对交通运输行业监测监管大屏，可以一键构建，简单调整布局即可用于项目实地开发。<br>",
        },
      ],
    };
  },
  mounted() {
    this.$router.afterEach((to, from, next) => {
      window.scrollTo(0, 0);
    });
  },
};
</script>

<style scoped>
.banner-box {
  height: 562px;
  background: url("https://sbyd-website.oss-cn-hangzhou.aliyuncs.com/assets/water/banner_sw.jpg")
    no-repeat center center;
  background-size: auto 100%;
}

.banner-title {
  font-size: 40px;
  line-height: 40px;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 56px;
}

.banner-shuoming {
  font-size: 24px;
  line-height: 48px;
  font-weight: 400;
  color: #ffffff;
}

.banner-custom span {
  text-align: left;
}

.yingyong-box {
  height: 810px;
  background: url("https://sbyd-website.oss-cn-hangzhou.aliyuncs.com/assets/heqi/bg_yyal.png")
    no-repeat center center;
  background-size: auto 100%;
}

.yingyong-title {
  font-size: 44px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 94px;
}

/deep/ .el-carousel__indicator--horizontal .el-carousel__button {
  margin-top: 26px;
  background-color: #d2d2d2;
  width: 86px;
  height: 5px;
  opacity: 1;
}

/deep/ .el-carousel__indicator--horizontal.is-active .el-carousel__button {
  width: 86px;
  height: 5px;
  background-color: #ffc244;
  opacity: 1;
}

.custom-linian {
  padding: 108px 0 131px 0;
}

.custom-linian > span {
  font-size: 44px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 50px;
}

.waiquan {
  width: 188px;
  height: 188px;
  border-radius: 50%;
  border: 1px dashed #3485ee;
  transition: all 0.3s ease-out;
}

.lansequan {
  width: 132px;
  height: 132px;
  border-radius: 50%;
  background: #3485ee;
}

.custom-item > span:nth-of-type(1) {
  font-size: 24px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 22px;
  margin-top: 50px;
}

.custom-item > span:nth-of-type(2) {
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 24px;
}

.custom-item:nth-of-type(1) {
  max-width: 300px;
}

.custom-item:nth-of-type(2) {
  max-width: 300px;
  margin-left: 100px;
  margin-right: 100px;
}

.custom-item:nth-of-type(3) {
  max-width: 300px;
  /*margin-right: 0px;*/
}

.custom-linian-box {
  padding: 0 54px;
}

.jiejuefangan {
  height: 884px;
  background: url("https://sbyd-website.oss-cn-hangzhou.aliyuncs.com/assets/heqi/bg_jiejuefangan.png")
    no-repeat center center;
  background-size: auto 100%;
}

.custom-jiejue > span {
  font-size: 44px;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 85px;
}

.shuoming {
  width: 342px;
  height: 533px;
  background: rgba(9, 22, 40, 0.27);
  margin-left: 23px;
}

.shuoming > span {
  display: block;
  width: 286px;
  height: 389px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #fff;
  text-align: justify;
}

.custom-yewu {
  padding: 134px 0 79px 0;
}

.custom-yewu > span {
  font-size: 44px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 80px;
}

.custom-yewu > div {
  width: 100%;
}

.custom-yewu-item > span:nth-of-type(1) {
  font-size: 24px;
  font-weight: bold;
  color: #333333;
  margin: 41px 0 22px 0;
}

.custom-yewu-item > span:nth-of-type(2) {
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 24px;
}

.waiquan:hover {
  animation: fanzhuan 0.4s forwards;
}

@keyframes fanzhuan {
  0% {
    transform: rotateY(0);
  }
  50% {
    transform: rotateY(90deg);
  }
  100% {
    transform: rotateY(0);
  }
}

.img-box {
  width: 317px;
  height: 198px;
  overflow: hidden;
}

.img-box > img {
  transition: all 0.3s ease-out;
}

.img-box > img:hover {
  transform: scale(1.2);
}
.swiper-box-box {
  height: 90px;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
}

.swiper-box-box > span {
  color: #fff;
  font-weight: bold;
  font-size: 20px;
}
.youshi-shuoming {
  max-width: 400px;
  padding-left: 20px;
  padding-right: 20px;
}
@media screen and (min-width: 1200px) and (max-width: 1300px) {
  .shuoming {
    /*display: block;*/
    width: 300px;
    /*height: 533px;*/
  }

  .custom-jiejue > div {
    transform: scale(0.9);
    /*width: 600px;
    display: block;
    height: auto;*/
  }

  .custom-yewu > div > div {
    transform: scale(0.9);
  }
}
</style>
